<template>
  <div style="padding: 20px">
    <p><br /></p>
    <h2>（一）就医陪诊权益服务</h2>
    <p>
      <u><strong>【服务特点】</strong></u>
    </p>
    <p>陪伴就医、资源协调、情感支持</p>
    <p>
      <u><strong>【什么是就医陪诊服务】</strong></u>
    </p>
    <p>
      就医陪诊服务是一项旨在为用户提供全方位、贴心、专业的医疗陪伴服务的项目。旨在优化用户的就医体验，减轻他们在就医过程中的负担和压力。
    </p>
    <p>
      <u><strong>【权益说明】</strong></u>
    </p>
    <p>
      (1) &nbsp; &nbsp;
      全国2400+医院线下陪诊服务，一对一专业护士陪诊服务，提示诊前注意事项，提前了解就诊及检查流程，熟悉医院环境，全程协调与陪伴，代取号、代取药、协助患者就医过程中各科室流转，协助用户与医生沟通病历病情，提高就诊效率。服务结束后返还就医陪诊小结。
    </p>
    <p>(2) &nbsp; &nbsp; 提前48小时预约，单次服务时常不超过4小时。</p>
    <p>
      (3) &nbsp; &nbsp;
      限车主本人120种重大疾病使用，30天激活等待期，取得二级及以上公立医院诊断后可以发起服务申请。
    </p>
    <p>(4) &nbsp; &nbsp; 车主权益券不可叠加使用，不可拆分，不兑现。</p>
    <p><br /></p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
